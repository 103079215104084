import React from "react"
import { DividedSection, Title, Text, Button} from "gatsby-theme-material-foundry"
import { Container, Box } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/SEO/seo"

const NotFoundPage = () => (
  <Layout>

    <SEO title="404: Not found" />
    <DividedSection height="85vh" black>
      <Container align="center">
    <Title variant="h1">Fant ikke siden :(</Title>
    <Text variant="h3">Denne siden eksisterer dessverre ikke</Text>
    <Box my={8}>
    <Button to="/" size="lg" color="primary">Gå tilbake til førstesiden</Button>
    </Box>
    </Container>
    </DividedSection>
  </Layout>
)

export default NotFoundPage
