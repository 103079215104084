/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from "./SchemaOrg"

function SEO({
  postDescription,
  lang = "no",
  meta,
  postTitle,
  slug,
  datePublished,
  isBlogPost,
  postImage,
}) {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "hvlskapewebsite.png" }) {
          childImageSharp {
            fixed(
              width: 500
            ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }
          }
        }
      }
    `
  )

  const title = postTitle ? `${postTitle} - HVL Skape` : site.siteMetadata.title
  const description = postDescription ? postDescription : site.siteMetadata.description
  const image = postImage
    ? postImage
    : `${site.siteMetadata.siteUrl}${file.childImageSharp.fixed.src}`
  const url = slug
    ? `${site.siteMetadata.siteUrl}/${slug}/`
    : site.siteMetadata.siteUrl

  const date = datePublished ? datePublished : false

  return (
    <React.Fragment>
      <Helmet>
        {/* General tags */}
        <html lang={lang} amp />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="canonical" href={url} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={site.siteMetadata.social.twitter}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={title}
        image={image}
        description={description}
        datePublished={date}
        siteUrl={site.siteMetadata.siteUrl}
        author={site.siteMetadata.author}
        organization={site.siteMetadata.organization}
        defaultTitle={site.siteMetadata.title}
      />
    </React.Fragment>
  )
}

export default SEO
